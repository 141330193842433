/* orizontal scroll - Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 4px;
    background: #111;
}

*::-webkit-scrollbar-thumb {
    background: #a2a2a2;
    border-radius: 10px;
}

.how_to_do::-webkit-scrollbar {
    background: #ffffff;
}

.myStrategy::-webkit-scrollbar {
    background: #12969b;
}

.myOurServices::-webkit-scrollbar {
    background: transparent;
}

.ourWorksBox::-webkit-scrollbar {
    height: 4px;
}

.ourWorksBox::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
    border-radius: 10px;
}

.ourWorksBox::-webkit-scrollbar-thumb {
    background: #e82283;
    border-radius: 10px;
}

.ourWorksBox::-webkit-scrollbar-thumb:hover {
    background: #14a7ad;
}

/* navbar */
.hamMenu {
    z-index: 9;
    top: 58px;
}

.hamMenu ul {
    list-style-type: none;
    line-height: 1.6;
    font-size: 25px;
    cursor: pointer;
}

.hamMenu ul a {
    text-decoration: none;
    color: #fff;
}

.hamMenu ul li>span:hover {
    color: #e82283;
}

.hamMenu .serviceBlock>a {
    cursor: pointer;
    color: #fff;
    text-decoration: none;
}

.hamMenu .serviceBlock div:hover {
    background-color: #e82283;
    border: 1px solid #e82283 !important;
    transition: .15s ease-in-out .03s;
}

.hamButton {
    cursor: pointer;
    font-size: 30px;
}

.hamButton:hover {
    transform: translateY(-2px);
    transition: .2s ease-in-out .05s;
}



/* animation style lottie */

.animTransIn {
    animation: animIn 3s infinite;
}


@keyframes animIn {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/* progressive cirle bar database stats */
.inner-div>.text-area>p:first-child {
    font-size: .8rem !important;
}

.inner-div>.text-area>p:last-child {
    font-size: .7rem !important;
    font-style: italic;
}

.dbButton .btn-light {
    --bs-btn-bg: #adadad !important;
    --bs-btn-color: #fff !important;
}

/* database how to do */
.mainIcons i {
    color: #17365d;
}

.efficacyScore {
    font-size: .6rem;
    color: #e82283 !important;
}

.priceScore {
    font-size: .6rem;
    color: #14a7ad !important;
}

.demScore>div>i:first-child {
    font-size: .7rem;
}

.demCard {
    cursor: pointer;
}

.demCard:hover {
    background: #f7f7f7;
    transition: .15s ease-in-out .05s;
}

/* All Our Services */
.allProductsCard {
    background-color: #333333e5;
    max-width: 600px;
}

/* About Us Team Card */
.authorCard {
    max-width: 350px;
    border: 2px solid #fff;
}

.authorCard>div:first-child {
    z-index: 8;
    border-radius: 25px;
    overflow: hidden
}


/* star rating */
.postRatingStars {
    font-size: 25px;
    margin-right: 3px;
}

[class*=bi-star] {
    color: orange !important;
}

/*delete visit alert */
.myAlert {
    position: absolute !important;
    z-index: 8;
    height: 100vh;
}

/* bold color */
.bg-dark b {
    color: #e82283;
    font-weight: 500;
}

/* cookie button */
.cookieBtn {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
}

/* pop-up */
.popupCallButton {
    z-index: 1;
    top: 55%;
}